import { getDevice } from '../../utils/device';

export function getTagsFromUrl(url) {
    if (!url) {
        return [url];
    }

    const urlObj = new URL(url);
    if (
        !urlObj.host.includes('www.vg.no') &&
        !urlObj.pathname.includes('annonsorinnhold')
    ) {
        return [url];
    }

    const { pathname } = new URL(url);

    const splitted = pathname.split('/');
    if (!splitted.length) {
        return [url];
    }

    return [
        ...splitted
            .filter(Boolean)
            .slice(1)
            .map((part) => part),
        url,
    ];
}

export function getTitle(article) {
    if (article) {
        const headline = article.querySelector('.headline');
        const drEditionTitle = article.querySelector('.dr-edition-title');

        if (headline?.textContent) {
            return headline.textContent.trim();
        }

        if (drEditionTitle?.textContent) {
            return drEditionTitle.textContent.trim();
        }

        return '';
    }

    return '';
}

export function getSubTitle(article) {
    if (article) {
        const insertTitle = article.querySelector('.insert-title');
        const drEditionSubtitle = article.querySelector('.dr-edition-subtitle');

        if (insertTitle?.textContent) {
            return insertTitle.textContent.trim();
        }

        if (drEditionSubtitle?.textContent) {
            return drEditionSubtitle.textContent.trim();
        }

        return '';
    }

    return '';
}

export function getPulseData(container) {
    if (!container) {
        return {
            variant: '',
            revision: '',
        };
    }

    const { dataset: pulseData } = container;
    const {
        pulseVariantId: variant,
        pulseRevisionId: revision,
        drfrontId: drfrontId,
        creativeId,
        itemId,
        editionId,
        algorithm,
    } = pulseData;

    return {
        variant,
        revision,
        drfrontId,
        creativeId,
        itemId,
        editionId,
        algorithm,
    };
}

export function getTags(target) {
    const container = target.querySelector('.container:not([hidden])');
    const linkElm = container?.querySelector('a');
    const link = linkElm?.href ?? '';

    return getTagsFromUrl(link);
}

export function getPartnerStudioObject(target, index) {
    const time = Math.floor(Date.now() / 1000);
    const container = target.querySelector('.container:not([hidden])');
    const imageUrl = container?.querySelector('.article-image')?.src || 'NULL';
    const linkElm = container?.querySelector('a');
    const link = linkElm?.href ?? '';
    const { id: placement } = target;
    const device = getDevice();
    const {
        variant,
        revision,
        drfrontId,
        creativeId,
        itemId,
        editionId,
        algorithm,
    } = getPulseData(container);

    return {
        type: 'view',
        tracking_type: 'ad',
        frontId: 86,
        time,
        placement,
        link,
        imageUrl,
        editionId,
        itemId,
        device,
        variant,
        revision,
        drfrontId,
        creativeId,
        index,
        xandrAdLoaded:
            target.nextElementSibling?.classList?.contains(
                'advert-loaded-wrap',
            ),
        title: getTitle(container),
        subTitle: getSubTitle(container),
        algorithm: algorithm,
    };
}
