import timeout from '../utils/timeout';
import { getItem, setItem } from './helpers';

const GEO_OPT_OUT_DISABLED = false;
const GLIMR_TAG_STORAGE_KEY = 'glimrTags';
const CLIENT_ID = 'H3IYO2A4LD43YPFZIJLN';
const GEOFENCE_TAGS_PREFIXES = ['glgc_', 'glpc_'];

let permissionsPromise;
export let getPermissions = () => {
    if (!permissionsPromise) {
        permissionsPromise = timeout(
            new Promise((resolve) => {
                function lbmpUpdated() {
                    const data = window.lbmpPermissions || null;
                    resolve(data);
                }

                if (window.lbmpPermissions !== undefined) {
                    lbmpUpdated();
                } else {
                    window.addEventListener('lbmp-updated', () => {
                        lbmpUpdated();
                    });
                }
            }),
            500,
        ).catch(() => null);
    }

    return permissionsPromise;
};

async function hasGeoAdConsent() {
    try {
        const permissions = await getPermissions();
        if (!permissions) {
            return true;
        }
        if (Array.isArray(permissions)) {
            return permissions.some(
                (p) =>
                    p.purpose.includes(
                        'processingpurpose:targeted_advertising',
                    ) &&
                    p.data.includes('sdrn:schibsted:datacategory:geolocation'),
            );
        }
    } catch (e) {
        return true;
    }
}

export async function allowedToUseGlimr() {
    const consentNotGranted =
        !window._tcf_?.getConsentedToAllSync ||
        !window._tcf_?.getConsentedToAllSync();

    if (window._tcf_ && consentNotGranted) {
        return false;
    }

    const canUseGlimr = await hasGeoAdConsent();
    return GEO_OPT_OUT_DISABLED || canUseGlimr;
}

async function getGlimrData() {
    let glimrData = getItem(GLIMR_TAG_STORAGE_KEY);

    return glimrData;
}

export const getGlimrTags = async () => {
    const consentNotGranted =
        !window._tcf_?.getConsentedToAllSync ||
        !window._tcf_?.getConsentedToAllSync();

    if (window._tcf_ && consentNotGranted) {
        return undefined;
    }

    const glimrData = await getGlimrData();
    if (!glimrData) {
        return undefined;
    }

    try {
        return glimrData?.tags;
    } catch (e) {
        return undefined;
    }
};

export function getGeoGlimrTags(glimrTags = []) {
    return glimrTags.filter(
        (tag) => !GEOFENCE_TAGS_PREFIXES.some((prefix) => tag.includes(prefix)),
    );
}

export function getGeofenceGlimrTags(glimrTags = []) {
    return glimrTags.filter((tag) =>
        GEOFENCE_TAGS_PREFIXES.some((prefix) => tag.includes(prefix)),
    );
}

const loadSdk = async () =>
    await import(/* webpackChunkName: "glimr-sdk" */ 'glimr-sdk');

let glimrSdkPromise;
const loadGlimr = () => {
    if (!glimrSdkPromise) {
        glimrSdkPromise = timeout(
            new Promise((resolve) =>
                loadSdk()
                    .then((glimr) => {
                        glimr.setTagCacheTimeInSeconds(300);

                        glimr.getTags(CLIENT_ID, (tags, tagMappings) => {
                            setItem(GLIMR_TAG_STORAGE_KEY, {
                                tags,
                                tagMappings,
                            });
                            resolve();
                        });
                    })
                    .catch(() => resolve()),
            ),
            1000,
        ).catch(() => {});
    }
    return glimrSdkPromise;
};

let glimrRequest;
export async function updateGlimr() {
    if (glimrRequest) {
        return glimrRequest;
    }

    const shouldLoadGlimr = await allowedToUseGlimr();
    if (shouldLoadGlimr) {
        glimrRequest = await loadGlimr();

        return glimrRequest;
    } else {
        return null;
    }
}

export default () => updateGlimr(); // For module loading logic in app.js
