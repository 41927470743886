import { trackEngagement } from '../../tracking/pulse';

const ACCESS_PATH =
    'https://access.schibsted.digital/v1/access?pids=100023,301784,301814,FEA-VG-PLUSARTICLES&spid_instance=spid.no&source=vg.no&sp_id=';

export async function getAccess(spidUser) {
    const response = await fetch(ACCESS_PATH + spidUser.sp_id);
    const products = await response.json();

    if (products.entitled) {
        return spidUser;
    }

    return false;
}

export function trackClick(widget, element) {
    const id = `pluss-front:${widget}:${element}`;

    trackEngagement({
        action: 'Click',
        elementId: id,
    });
}
