/*global autoplayQueue */
import getScript from './get-script';

export default async () => {
    if (!window.autoplayQueue) {
        return getScript(
            'https://www.vg.no/vgc/vgtv-autoplay/autoplay-preload-latest.js',
        );
    }

    autoplayQueue.push(({ parse }) => parse());
};
