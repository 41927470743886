function setItem(key, value) {
    const dataToPersist = {
        data: value,
        timestamp: new Date().getTime(),
    };
    window.localStorage.setItem(key, JSON.stringify(dataToPersist));
}

function getItem(key) {
    const item = window.localStorage.getItem(key);
    if (!item) return undefined;
    try {
        const { timestamp, data } = JSON.parse(item);
        const nowMS = new Date().getTime();
        const MAX_CACHE_AGE = 24 * 60 * 60 * 1000;
        if (timestamp + MAX_CACHE_AGE > nowMS) {
            return data;
        }

        return undefined;
    } catch (e) {
        return undefined;
    }
}

export { setItem, getItem };
