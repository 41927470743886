import { preconnect } from '../utils';
import { getEnvironmentId } from './pulse';
import { hasSession } from '../auth/schibsted-account';

const config = {
    endpointIdentified:
        'https://user-permissions.privacy.schibsted.com/privacy/v2/schacc/vg/user/',
    endpointAnonymous:
        'https://user-permissions.privacy.schibsted.com/privacy/v2/anonymous/vg/user/',
    sdrnIdentified: 'sdrn:spid.no:user',
    sdrnAnonymous: 'sdrn:schibsted:environment',
    key: 'lbmp-permissions',
    ttl: 14400,
};

const { endpointIdentified, endpointAnonymous, sdrnIdentified, sdrnAnonymous } =
    config;

const getStorageKey = (userKey) => config.key + '-' + userKey;

export const getPermissions = () =>
    new Promise((resolve) => {
        const lbmpTimeout = setTimeout(() => lbmpUpdated(), 3000);
        function lbmpUpdated() {
            resolve(window.lbmpPermissions || null);
            window.removeEventListener('lbmp-updated', lbmpUpdated);
            clearTimeout(lbmpTimeout);
        }

        if (window.lbmpPermissions !== undefined) {
            resolve(window.lbmpPermissions);
        } else {
            window.addEventListener('lbmp-updated', lbmpUpdated);
        }
    });

function sendLBMPUpdatedEvent() {
    const event = new window.CustomEvent('lbmp-updated', {});
    window.dispatchEvent(event);
}

function clearHasSessionCache() {
    try {
        localStorage.removeItem('hasSession-cache');
    } catch (e) {
        // silent catch
    }
}

function setPermissions(permissions, userKey) {
    sessionStorage.setItem(getStorageKey(userKey), JSON.stringify(permissions));

    window.lbmpPermissions = permissions;
    sendLBMPUpdatedEvent();
}

function clearPermissions() {
    const storageItems = sessionStorage;

    for (const key in storageItems) {
        if (key.indexOf(config.key + '-') == 0) sessionStorage.removeItem(key);
    }
}

async function fetchPermissions(endpoint, userId, userSig) {
    clearPermissions();

    const headers = new Headers();
    if (userSig) {
        headers.set('Authorization', 'SPIDSIG ' + userSig);
    }

    try {
        const res = await fetch(endpoint + userId + '/permissions', {
            headers,
        });
        const data = await res.json();
        setPermissions(data.permissions, userId);
    } catch (e) {
        setPermissions(null, userId);
    }
}

function permissionsAreCached(userKey) {
    return !!sessionStorage.getItem(getStorageKey(userKey));
}

function getSessionAndEnvironmentId() {
    return Promise.all([
        hasSession().catch(() => ({
            result: false,
        })),
        getEnvironmentId(),
    ]).then(([session, environmentId]) => ({
        session,
        environmentId,
    }));
}

async function initFetchPermissions() {
    preconnect('https://user-permissions.privacy.schibsted.com', 'anonymous');
    const { session, environmentId } = await getSessionAndEnvironmentId();
    if (session && session.result) {
        // logged in
        const identifiedId = session.userId
            ? sdrnIdentified + ':' + session.userId
            : sdrnAnonymous + ':' + environmentId;

        const cached = permissionsAreCached(identifiedId);

        if (session.userId && !cached) {
            const userSig = session.sig;
            fetchPermissions(endpointIdentified, identifiedId, userSig);
        } else {
            const key = getStorageKey(identifiedId);

            try {
                const storageData = sessionStorage.getItem(key) || '{}';
                window.lbmpPermissions = JSON.parse(storageData);
            } catch (e) {
                // silent catch
                window.lbmpPermissions = null;
            }

            sendLBMPUpdatedEvent();

            window.SPiD_Identity.on('logout', () => {
                clearHasSessionCache();
                clearPermissions();
                // initFetchPermissions();
            });
        }
    } else {
        // not logged in
        const identifiedId = sdrnAnonymous + ':' + environmentId;
        const cached = permissionsAreCached(identifiedId);

        if (environmentId && !cached) {
            fetchPermissions(endpointAnonymous, identifiedId);
        } else {
            const key = getStorageKey(identifiedId);

            try {
                const storageData = sessionStorage.getItem(key) || '{}';
                window.lbmpPermissions = JSON.parse(storageData);
            } catch (e) {
                // silent catch
            }
        }

        sendLBMPUpdatedEvent();
    }
}

export default () => initFetchPermissions();
