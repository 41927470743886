export default (promise, ms, message = 'timeout') => {
    return Promise.race([
        promise,
        new Promise((_, reject) => {
            const timeout = setTimeout(() => reject(new Error(message)), ms);
            const _clearTimeout = () => {
                clearTimeout(timeout);
            };
            promise.then(_clearTimeout, _clearTimeout);
        }),
    ]);
};
