/**
 * Fetch url and get json
 *
 * @param {RequestInfo} url
 * @param {RequestInit?} options
 * @returns {Promise<any>}
 */
export async function fetchJson(url, options = {}) {
    const req = await fetch(url, options);
    return req.json();
}
