import { useState, useEffect } from 'preact/hooks';
import { trackClick } from './utils';
import s from '../../../sass/widgets/_vgtv-premium-sport.module.scss';

export default function Carosell({ feed, type, placeholder, access, content }) {
    const Content = content;
    const [pagination, setPagination] = useState(0);

    const changePage = (changeTo) => {
        trackClick('pluss-documentaries', 'pagination');

        if (changeTo < 0) {
            setPagination(feed.length - 1);
        } else if (changeTo > feed.length - 1) {
            setPagination(0);
        } else {
            setPagination(changeTo);
        }
    };

    useEffect(() => {
        document.querySelector(
            placeholder,
        ).style.backgroundImage = `url(${feed[pagination].images.main}?t[]=1440x810q80)`;
    }, [pagination]);

    return (
        <div class="card-inner">
            <div class={s.header}>
                <h2 class="section">{type}</h2>
                {feed.length > 1 && (
                    <div class={s.breadcrumb}>
                        {feed.map((_, index) => (
                            <span
                                key={index}
                                class={pagination == index ? s.active : ''}
                            ></span>
                        ))}
                    </div>
                )}
            </div>
            {feed.length > 1 && (
                <div class={s.nav}>
                    <div
                        class={s.left}
                        onClick={() => changePage(pagination - 1)}
                    >
                        <svg
                            data-token-name="arrow-left"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.29289 7.29289C8.68342 6.90237 9.31658 6.90237 9.70711 7.29289C10.0976 7.68342 10.0976 8.31658 9.70711 8.70711L7.414 11H19C19.5128 11 19.9355 11.386 19.9933 11.8834L20 12C20 12.5523 19.5523 13 19 13H7.414L9.70711 15.2929C10.0676 15.6534 10.0953 16.2206 9.7903 16.6129L9.70711 16.7071C9.31658 17.0976 8.68342 17.0976 8.29289 16.7071L4.29289 12.7071L4.21969 12.6254C4.21737 12.6226 4.21507 12.6197 4.21279 12.6167C4.20728 12.6098 4.20175 12.6026 4.19633 12.5953C4.17849 12.5711 4.16172 12.5463 4.14607 12.5207C4.13861 12.5086 4.1315 12.4964 4.12467 12.4841C4.11379 12.4643 4.10338 12.4439 4.09365 12.4231C4.08568 12.4061 4.0782 12.3888 4.07123 12.3713C4.06332 12.3516 4.05612 12.3318 4.04955 12.3117C4.04473 12.2969 4.0401 12.2815 4.03585 12.266C4.02983 12.2444 4.02464 12.2227 4.02016 12.2008C4.0167 12.1834 4.01363 12.1659 4.01102 12.1485C4.00376 12.1004 4 12.0506 4 12L4.00397 12.0893C4.00189 12.066 4.00063 12.0427 4.00019 12.0194L4 12C4 11.9936 4.00006 11.9872 4.00018 11.9808C4.0006 11.958 4.00187 11.9343 4.00397 11.9107C4.00577 11.8902 4.00811 11.8705 4.01102 11.851C4.01363 11.8341 4.0167 11.8166 4.02024 11.7993C4.02464 11.7773 4.02983 11.7556 4.03572 11.7342C4.0401 11.7185 4.04473 11.7031 4.04974 11.6879C4.05612 11.6682 4.06332 11.6484 4.0711 11.6289C4.0782 11.6112 4.08568 11.5939 4.09367 11.5768C4.10338 11.5561 4.11379 11.5357 4.12488 11.5157C4.1315 11.5036 4.13861 11.4914 4.146 11.4793C4.16172 11.4537 4.17849 11.4289 4.19631 11.4048C4.22531 11.3657 4.2575 11.3283 4.29289 11.2929L4.21279 11.3833C4.23767 11.3515 4.26443 11.3214 4.29289 11.2929L8.29289 7.29289Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    <div
                        class={s.right}
                        onClick={() => changePage(pagination + 1)}
                    >
                        <svg
                            data-token-name="arrow-right"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M14.2929 7.29289C14.6834 6.90237 15.3166 6.90237 15.7071 7.29289L19.7071 11.2929C19.7356 11.3214 19.7623 11.3515 19.7872 11.3833C19.7927 11.3902 19.7982 11.3974 19.8037 11.4047C19.8215 11.4289 19.8383 11.4537 19.8539 11.4793C19.8614 11.4914 19.8685 11.5036 19.8753 11.5159C19.8862 11.5357 19.8966 11.5561 19.9063 11.5769C19.9143 11.5939 19.9218 11.6112 19.9288 11.6287C19.9367 11.6484 19.9439 11.6682 19.9505 11.6883C19.9553 11.7031 19.9599 11.7185 19.9642 11.734C19.9702 11.7556 19.9754 11.7773 19.9798 11.7992C19.9833 11.8166 19.9864 11.8341 19.989 11.8515C19.9962 11.8996 20 11.9494 20 12L19.9963 11.9138C19.9978 11.9317 19.9989 11.9497 19.9995 11.9677L20 12C20 12.0112 19.9998 12.0224 19.9994 12.0335C19.9989 12.0506 19.9979 12.0679 19.9964 12.0852C19.9946 12.1071 19.9921 12.1281 19.989 12.149C19.9864 12.1659 19.9833 12.1834 19.9798 12.2007C19.9754 12.2227 19.9702 12.2444 19.9643 12.2658C19.9599 12.2815 19.9553 12.2969 19.9503 12.3121C19.9439 12.3318 19.9367 12.3516 19.9289 12.3711C19.9218 12.3888 19.9143 12.4061 19.9063 12.4232C19.8966 12.4439 19.8862 12.4643 19.8751 12.4843C19.8685 12.4964 19.8614 12.5086 19.854 12.5207C19.8383 12.5463 19.8215 12.5711 19.8037 12.5952C19.7747 12.6343 19.7425 12.6717 19.7071 12.7071L19.7872 12.6167C19.7849 12.6197 19.7826 12.6226 19.7803 12.6254L19.7071 12.7071L15.7071 16.7071C15.3166 17.0976 14.6834 17.0976 14.2929 16.7071C13.9024 16.3166 13.9024 15.6834 14.2929 15.2929L16.585 13H5C4.48716 13 4.06449 12.614 4.00673 12.1166L4 12C4 11.4477 4.44772 11 5 11H16.585L14.2929 8.70711C13.9324 8.34662 13.9047 7.77939 14.2097 7.3871L14.2929 7.29289Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                </div>
            )}
            <Content access={access} asset={feed[pagination]} />
        </div>
    );
}
