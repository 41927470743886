export default (src, id = null, type = 'text/javascript', nomodule = false) =>
    new Promise((resolve, reject) => {
        if (id && document.getElementById(id)) {
            return resolve();
        }

        const script = document.createElement('script');
        script.src = src;

        if (id) {
            script.id = id;
        }

        script.type = type;
        script.setAttribute('noshim', '');

        script.async = true;
        if ('noModule' in script && !nomodule) {
            script.addEventListener('load', () => resolve());
        } else {
            resolve();
        }
        script.addEventListener('error', () =>
            reject(new Error(`Failed to fetch script ${src}`)),
        );

        if (nomodule) {
            script.setAttribute('nomodule', '');
        }

        document.head.appendChild(script);
    });
