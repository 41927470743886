import { useEffect, useState } from 'preact/hooks';
import { render } from 'preact';
import s from '../../sass/widgets/front-countdown.module.scss';

function getCountdownNodes() {
    const nodes = $$('.countdown');
    return nodes;
}

function zeroPad(unit) {
    return unit < 10 ? `0${unit}` : unit;
}

function getTimezoneOffset(end) {
    return new Date().getTimezoneOffset() - end.getTimezoneOffset();
}

function getTimeRemaining(endtime) {
    const total =
        Date.parse(endtime) -
        Date.parse(new Date()) -
        getTimezoneOffset(endtime);

    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
        total,
        days,
        hours,
        minutes,
        seconds,
    };
}

const separator = (sep = ':') => <span class={s.separator}>{sep}</span>;

function getTimeRemainingFromNode(node) {
    const { countdown = null } = node.dataset;
    if (!countdown) {
        return {};
    }

    const end = new Date(countdown);
    return getTimeRemaining(end);
}

const Countdown = ({ node }) => {
    const [counter, setCounter] = useState(getTimeRemainingFromNode(node));

    useEffect(() => {
        const timeout = setInterval(() => {
            setCounter(() => getTimeRemainingFromNode(node));
        }, 1000);

        return () => clearInterval(timeout);
    }, []);
    const { days, hours, minutes, seconds } = counter;

    const html = [];

    if (days > 0) {
        html.push(
            <span class={s.days} data-unit="days">
                {days}d&nbsp;
            </span>,
        );
    }

    if (hours >= 0) {
        html.push(
            <span class={s.minutes} data-unit="hours">
                {zeroPad(hours)}
            </span>,
        );
        html.push(separator());
    }

    if (minutes >= 0) {
        html.push(
            <span class={s.minutes} data-unit="minutes">
                {zeroPad(minutes)}
            </span>,
        );
        html.push(separator());
    }

    if (seconds >= 0) {
        html.push(
            <span class={s.seconds} data-unit="seconds">
                {zeroPad(seconds)}
            </span>,
        );
    }

    return html;
};

function renderCountdown(node) {
    const html = <Countdown node={node} />;
    render(<div class={s.root}>{html}</div>, node);
}

function reveal(node) {
    node.classList.add(s.fadeIn);
}

function init() {
    const nodes = getCountdownNodes();

    nodes.forEach((node) => renderCountdown(node));
    $$('.countdown-wrapper').forEach((node) => reveal(node));
}

export default () => init();
