import Storage from './Storage';

const viewHistory = (storageKey) => {
    storageKey = `__vgfront-history-${storageKey}`;
    const storage = new Storage(storageKey);

    const store = {
        has: (id) => {
            const data = storage.getAll();

            if (data && data[id]) {
                return true;
            }

            return false;
        },

        get: (id) => {
            const data = storage.getAll();
            return data[id];
        },

        getAll: () => {
            return storage.getAll();
        },

        increase: (id) => {
            let count = 0;

            if (store.has(id)) {
                count = store.get(id).count;
            }

            storage.set(id, {
                count: ++count,
            });
        },

        clearOutdated: () => {
            storage.clearOutDated();
        },

        setViewed: (id, expiration) => {
            let count = 0;

            if (store.has(id)) {
                count = store.get(id).count;
            }

            storage.set(id, {
                expiration: expiration,
                count: ++count,
            });
        },

        setAlgorithm: (id, algorithm, expiration) => {
            storage.set(id, {
                type: algorithm,
                expiration: expiration,
            });
        },

        clearExpired: (hours) => storage.clearExpired(hours),

        clearAll: () => storage.clearAll(),

        partnerstudioGetAll: () => storage.partnerstudioGetAll(),

        getAlgorithm: () => storage.getAlgorithm(),
    };

    return store;
};
export default viewHistory;
