class Storage {
    constructor(storageKey) {
        this.storageKey = storageKey;
    }

    getAll() {
        const currentData = JSON.parse(localStorage.getItem(this.storageKey));
        if (currentData) {
            return currentData;
        }

        return {};
    }

    get(id) {
        const data = this.getAll();
        return data[id];
    }

    set(key, value) {
        const data = {
            [key]: {
                ...value,
                time: Date.now(),
            },
        };

        const currentData = this.getAll();
        localStorage.setItem(
            this.storageKey,
            JSON.stringify({
                ...currentData,
                ...data,
            }),
        );
    }

    clearExpired(hours = 12) {
        const currentData = this.getAll();
        const isExpired = ({ time }) =>
            (Date.now() - time) / 1000 / 60 / 60 > hours;

        for (const id in currentData) {
            if (isExpired(currentData[id])) {
                delete currentData[id];
            }
        }

        localStorage.setItem(this.storageKey, JSON.stringify(currentData));
    }

    partnerstudioGetAll() {
        const currentData =
            JSON.parse(localStorage.getItem(this.storageKey)) || {};

        const currentDate = new Date();
        const isExpired = ({ expiration }) => {
            return currentDate >= expiration;
        };
        //Clear all expiered before returning & updating all data
        for (const id in currentData) {
            if (isExpired(currentData[id])) {
                delete currentData[id];
            }
        }
        localStorage.setItem(this.storageKey, JSON.stringify(currentData));

        return currentData;
    }

    getAlgorithm() {
        if (JSON.parse(localStorage.getItem(this.storageKey))) {
            return JSON.parse(localStorage.getItem(this.storageKey))[
                'algorithm'
            ];
        }
        return undefined;
    }

    clearAll() {
        let currentData = JSON.parse(localStorage.getItem(this.storageKey));

        if (!currentData || !currentData['algorithm']) {
            //set empty obj
            localStorage.setItem(this.storageKey, JSON.stringify({}));
        } else {
            //keep type of algorithm
            localStorage.setItem(
                this.storageKey,
                JSON.stringify({ algorithm: currentData['algorithm'] }),
            );
        }
    }
}

export default Storage;
