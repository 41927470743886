const getWebStorageItem = (key) => {
    try {
        return window.localStorage.getItem(key);
    } catch {
        return null;
    }
};

const setWebStorageItem = (key, value) => {
    try {
        window.localStorage.setItem(key, value);
        // eslint-disable-next-line no-empty
    } catch {}
};

const getRandomVariant = (variants) => {
    const target = Math.floor(Math.random() * 100);

    const variantIndex = variants
        .reduce((acc, curr, index) => {
            const prev = acc[index - 1] ?? 0;

            acc.push(prev + curr.weight);

            return acc;
        }, [])
        .findIndex((threshold) => target <= threshold);

    return variants[variantIndex].name;
};

const getStorageKey = (prefix, id) => {
    return `${prefix}/${id}`;
};

export const resolveExperiment = ({
    experiment,
    storagePrefix = 'vg-front',
}) => {
    const { name, variants } = experiment;

    const variant =
        getWebStorageItem(getStorageKey(storagePrefix, name)) ||
        getRandomVariant(variants);

    setWebStorageItem(getStorageKey(storagePrefix, name), variant);

    return {
        variant,
    };
};
