import { render } from 'preact';
import classNames from 'clsx';
import s from '../../../sass/widgets/_pluss-resources.module.scss';

export default function PlussResources({ resources, title, insertPoint }) {
    render(
        <>
            <h2 class="section section-header">{title}</h2>
            <div class={s.resources}>
                {resources.map((resource) => (
                    <a
                        href={resource.link}
                        key={resource.title}
                        class={classNames(
                            s.resource,
                            resource.largeImage && s.largeImage,
                        )}
                    >
                        <img
                            class={s.image}
                            src={resource.image}
                            alt="illustrasjon"
                        />
                        <div class={s.text}>
                            <div class={s.title}>{resource.title}</div>
                            <div class={s.description}>
                                {resource.description}
                            </div>
                        </div>
                        <div class={s.button}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M14.9595 7.29289C15.3501 6.90237 15.9832 6.90237 16.3738 7.29289L20.3738 11.2929C20.4022 11.3214 20.429 11.3515 20.4539 11.3833C20.4594 11.3902 20.4649 11.3974 20.4703 11.4047C20.4882 11.4289 20.5049 11.4537 20.5206 11.4793C20.5281 11.4914 20.5352 11.5036 20.542 11.5159C20.5529 11.5357 20.5633 11.5561 20.573 11.5769C20.581 11.5939 20.5885 11.6112 20.5954 11.6287C20.6033 11.6484 20.6105 11.6682 20.6171 11.6883C20.6219 11.7031 20.6266 11.7185 20.6308 11.734C20.6368 11.7556 20.642 11.7773 20.6465 11.7992C20.65 11.8166 20.653 11.8341 20.6556 11.8515C20.6629 11.8996 20.6667 11.9494 20.6667 12L20.663 11.9138C20.6645 11.9317 20.6656 11.9497 20.6661 11.9677L20.6667 12C20.6667 12.0112 20.6665 12.0224 20.6661 12.0335C20.6655 12.0506 20.6645 12.0679 20.663 12.0852C20.6612 12.1071 20.6588 12.1281 20.6556 12.149C20.653 12.1659 20.65 12.1834 20.6464 12.2007C20.642 12.2227 20.6368 12.2444 20.6309 12.2658C20.6266 12.2815 20.6219 12.2969 20.6169 12.3121C20.6105 12.3318 20.6033 12.3516 20.5956 12.3711C20.5885 12.3888 20.581 12.4061 20.573 12.4232C20.5633 12.4439 20.5529 12.4643 20.5418 12.4843C20.5352 12.4964 20.528 12.5086 20.5207 12.5207C20.5049 12.5463 20.4882 12.5711 20.4703 12.5952C20.4413 12.6343 20.4092 12.6717 20.3738 12.7071L20.4539 12.6167C20.4516 12.6197 20.4493 12.6226 20.447 12.6254L20.3738 12.7071L16.3738 16.7071C15.9832 17.0976 15.3501 17.0976 14.9595 16.7071C14.569 16.3166 14.569 15.6834 14.9595 15.2929L17.2517 13H5.66666C5.15382 13 4.73115 12.614 4.67338 12.1166L4.66666 12C4.66666 11.4477 5.11437 11 5.66666 11H17.2517L14.9595 8.70711C14.5991 8.34662 14.5713 7.77939 14.8764 7.3871L14.9595 7.29289Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                    </a>
                ))}
            </div>
        </>,
        insertPoint,
    );
}
